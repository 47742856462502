define("@smith-carson/ui/templates/components/sc-light-table/cells/high-volume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vvXrQ4B7",
    "block": "{\"symbols\":[\"@row\"],\"statements\":[[4,\"if\",[[24,1,[\"useJsiAndScPortal\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[5,\"sc-icon\",[[13,\"title\",\"High Volume\"]],[[\"@icon\",\"@iconSet\"],[\"users\",\"fa\"]]],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[0,\"High Volume Case\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-light-table/cells/high-volume.hbs"
    }
  });

  _exports.default = _default;
});