define("js-common/models/subject-tag", ["exports", "ember-data", "js-common/mixins/random-color-generator"], function (_exports, _emberData, _randomColorGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend(_randomColorGenerator.default, {
    name: attr('string'),
    color: attr('string'),
    internal: attr('number', {
      defaultValue: 1
    }),
    caseCount: attr('number', {
      defaultValue: 0
    }),
    publicOnCases: attr('string'),
    public: attr('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});