define("js-common/utils/subject-tag-colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var subjectTagColors = ['#10b4b0', '#faa92c', '#ea601c', '#7d54a2', '#2c71b4', '#e93e97', '#374c58', '#4993da', '#213e47', '#555555', '#349794', '#009933', '#cf515f', '#899da9'];
  var _default = subjectTagColors;
  _exports.default = _default;
});