define("@smith-carson/ui/templates/components/sc-color-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2PsbYlel",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"color\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"sc-color-chip\"],[12,\"style\",[29,\"concat\",[\"background-color: \",[25,[\"color\"]],\";\"],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-color-chip.hbs"
    }
  });

  _exports.default = _default;
});